import React, { useContext } from "react";

import { Link, PageProps } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";

import TypographyHeadline from "../components/1-atoms/TypographyHeadline/TypographyHeadline";
import RichText from "../components/2-molecules/RichText/RichText";

import { cookieStore } from "../cookie-layer/context";
import { cookieList } from "../cookie-layer/cookies";

import { addLineBreaks } from "../helper/strings";

export default function Home(props: PageProps): JSX.Element {
  // cookie context
  const cookieState = useContext(cookieStore);

  return (
    <>
      <GatsbySeo title="Datenschutz | Hautstark" nofollow noindex />

      <RichText>
        <TypographyHeadline size="h1">Datenschutzerklärung</TypographyHeadline>

        <p>
          <br />
          Vielen Dank für Ihren Besuch und Ihr Interesse an unserer Website{" "}
          <Link to="/">www.hautstark.com</Link>. Der Schutz Ihrer
          personenbezogenen Daten und die Wahrung Ihrer Online-Privatsphäre ist
          der Pierre Fabre-Gruppe ein großes Anliegen.
        </p>

        <p>
          Mit dieser Datenschutzerklärung möchten wir Sie über die Erhebung
          personenbezogener Daten während des Besuchs und der Nutzung unserer
          Website sowie über Art, Umfang, Zweck, Dauer und die Rechtsgrundlage
          der Verarbeitung Ihrer personenbezogenen Daten informieren.
        </p>
        <p>
          <strong>Wer sind wir?</strong>
          <br />
          Betreiber dieser Website (nachfolgend die <strong>„Website“</strong>)
          und Verantwortlicher für die Verarbeitung Ihrer personenbezogenen
          Daten, die wir während Ihres Besuches unserer Website für die im
          Folgenden beschriebenen Zwecke erheben, ist{" "}
          <strong>
            Pierre Fabre Dermo-Kosmetik GmbH, Jechtinger Straße 13, 79111
            Freiburg,{" "}
            <a href="mailto:kosmetische-information_de@pierre-fabre.com">
              kosmetische-information_de@pierre-fabre.com
            </a>
          </strong>{" "}
          (nachfolgend <strong>„Pierre Fabre“</strong> oder{" "}
          <strong>„Wir“</strong> oder <strong>„Uns“</strong> oder{" "}
          <strong>„Unser/Unsere“</strong> genannt).
          <br />
          Bei allen Fragen und als Ansprechpartner zum Thema Datenschutz steht
          Ihnen unser Datenschutz&shy;beauftragter jederzeit zur Verfügung.
          Gerne können Sie ihn direkt per E-Mail kontaktieren:{" "}
          <a href="mailto:dpode@pierre-fabre.com">dpode@pierre-fabre.com</a>.
        </p>

        <p>
          Wir bitten Sie, diese Datenschutzerklärung sorgfältig durchzulesen und
          sich über die Bedingungen der Verarbeitung Ihrer personenbezogenen
          Daten zu informieren, die wir während Ihres Besuches auf unserer
          Website erfassen. Wir machen Sie darauf aufmerksam, dass Pierre Fabre
          sich das Recht vorbehält, diese Datenschutzerklärung jederzeit zu
          ändern. Das Datum der aktuellsten Version unserer Datenschutzerklärung
          entnehmen Sie bitte dieser Seite. Wir empfehlen Ihnen deshalb, die
          Datenschutzerklärung unserer Website regelmäßig aufzurufen.
        </p>

        <p>
          <strong>Begriffsbestimmungen </strong>
          <br />
          <strong>
            Nach dem Vorbild des Art. 4 Datenschutzgrundverordnung (EU) 2016/679
            („DSGVO“) liegen dieser Datenschutzerklärung folgende
            Begriffsbestimmungen zugrunde:
          </strong>
        </p>
        <ul>
          <li>
            <strong>„Personenbezogene Daten“</strong> sind alle Informationen,
            die sich auf eine identifizierte oder identifizierbare natürliche
            Person („Betroffener“) beziehen. Identifizierbar ist eine Person,
            wenn sie direkt oder indirekt, insbesondere mittels Zuordnung zu
            einer Kennung wie einem Namen, einer Kennnummer, einer
            Online-Kennung, Standortdaten oder mithilfe von Informationen zu
            ihren physischen, physiologischen, genetischen, psychischen,
            wirtschaftlichen, kulturellen oder sozialen Identitätsmerkmalen
            identifiziert werden kann. Die Identifizierbarkeit kann auch mittels
            einer Verknüpfung von derartigen Informationen oder anderem
            Zusatzwissen gegeben sein. Auf das Zustandekommen, die Form oder die
            Verkörperung der Informationen kommt es nicht an (auch Fotos, Video-
            oder Tonaufnahmen können personenbezogene Daten enthalten) (Art. 4
            Nr. 1 DSGVO).
          </li>

          <li>
            <strong>„Gesundheitsdaten“</strong> sind personenbezogene Daten, die
            sich auf die körperliche oder geistige Gesundheit einer natürlichen
            Person, einschließlich der Erbringung von
            Gesundheitsdienstleistungen, beziehen und aus denen Informationen
            über deren Gesundheitszustand hervorgehen (Art. 4 Nr. 15 DSGVO).
          </li>

          <li>
            <strong>„Datenverarbeitung“</strong> steht für jeden mit oder ohne
            Hilfe automatisierter Verfahren ausgeführten Vorgang oder jede
            solche Vorgangsreihe im Zusammenhang mit personenbezogenen Daten wie
            das Erheben, das Erfassen, die Organisation, das Ordnen, die
            Speicherung, die Anpassung oder Veränderung, das Auslesen, das
            Abfragen, die Verwendung, die Offenlegung durch Übermittlung,
            Verbreitung oder eine andere Form der Bereitstellung, den Abgleich
            oder die Verknüpfung, die Einschränkung, das Löschen oder die
            Vernichtung. (Art. 4 Nr. 2 DSGVO).
          </li>

          <li>
            <strong>„Verantwortlicher“</strong> ist die natürliche oder
            juristische Person, Behörde, Einrichtung oder andere Stelle, die
            allein oder gemeinsam mit anderen über die Zwecke und Mittel der
            Verarbeitung von personenbezogenen Daten entscheidet (Art. 4 Nr. 7
            DSGVO).
          </li>

          <li>
            <strong>„Auftragsverarbeiter“</strong> ist eine natürliche oder
            juristische Person, Behörde, Einrichtung oder andere Stelle, die
            personenbezogene Daten im Auftrag des Verantwortlichen, insbesondere
            gemäß dessen Weisungen, verarbeitet (z. B. IT-Dienstleister). Im
            datenschutzrechtlichen Sinne ist ein Auftragsverarbeiter
            insbesondere kein Dritter (Art. 4 Nr. 8 DSGVO).
          </li>

          <li>
            <strong>„Dritter“</strong> ist jede natürliche oder juristische
            Person, Behörde, Einrichtung oder andere Stelle außer dem
            Betroffenen, dem Verantwortlichen, dem Auftragsverarbeiter und den
            Personen, die unter der unmittelbaren Verantwortung des
            Verantwortlichen oder Auftragsverarbeiters befugt sind, die
            personenbezogenen Daten zu verarbeiten; dazu gehören auch andere
            konzernangehörige juristische Personen (Art. 4 Nr. 10 DSGVO).
          </li>

          <li>
            <strong>„Einwilligung“</strong> der betroffenen Person bezeichnet
            jede freiwillig für den bestimmten Fall, in informierter Weise und
            unmissverständlich abgegebene Willensbekundung in Form einer
            Erklärung oder einer sonstigen eindeutigen bestätigenden Handlung,
            mit der die betroffene Person zu verstehen gibt, dass sie mit der
            Verarbeitung der sie betreffenden personenbezogenen Daten
            einverstanden ist (Art. 4 Nr. 11 DSGVO).
          </li>
        </ul>

        <p>
          <strong>Einwilligung</strong>
          <br />
          Für die Verwendung Ihrer Daten sind die jeweils geltenden gesetzlichen
          Bestimmungen, insbesondere das Bundesdatenschutzgesetz, die DSGVO und
          das Telemediengesetz, maßgeblich. Pierre Fabre wird Sie jeweils um
          Ihre ausdrückliche Einwilligung bitten, sofern Pierre Fabre Daten für
          einen Zweck nutzen will, der nach den gesetzlichen Bestimmungen Ihre
          Einwilligung erfordert. Sie können die einmal gegebene Einwilligung
          jederzeit mit Wirkung für die Zukunft widerrufen und/oder der
          zukünftigen Verwendung Ihrer Daten widersprechen. Die Rechtmäßigkeit
          der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung
          wird hiervon nicht berührt.
          <br />
          Die Kenntnisnahme von dieser Erklärung ersetzt Ihre ausdrückliche
          Einwilligungserklärung nicht.
          <br />
          Diese Datenverarbeitung erfolgt auf Grundlage Ihrer Einwilligung in
          die Verarbeitung der erforderlichen Daten (Art. 6 Abs. 1 S. 1 Buchst.
          a) DSGVO). Handelt es sich um Gesundheitsdaten, erfolgt die
          Verarbeitung auf Grundlage Ihrer ausdrücklichen Einwilligung gemäß
          Art. 9 Abs. 2 Buchst. a) DSGVO.
        </p>
        <p>
          <strong>Gesetzliche Erlaubnistatbestände</strong>
          <br />
          Das Datenschutzrecht sieht neben der Einwilligung auch weitere,
          gesetzliche Erlaubnistatbestände vor, welche es Pierre Fabre
          gestatten, Ihre personenbezogenen Daten zu verarbeiten. Zu nennen sind
          hier etwa die Verarbeitung personenbezogene Daten zum Zwecke der
          Erfüllung vertraglicher Pflichten (Art. 6 Abs. 1 S. 1 Buchst. b)
          DSGVO) oder die Verarbeitung solcher Daten zur Wahrung berechtigter
          Interessen des Verantwortlichen oder eines Dritten (Art. 6 Abs. 1 S. 1
          Buchst. f) DSGVO).
          <br />
          Soweit die entsprechenden gesetzlichen Erlaubnistatbestände
          einschlägig sind, verarbeitet Pierre Fabre Ihre personenbezogenen
          Daten in den gesetzlich vorgegebenen Grenzen und zu den gesetzlich
          erlaubten Zwecken.
        </p>

        <TypographyHeadline size="h2" tag="h2">
          Datenerhebung und –verarbeitung
        </TypographyHeadline>
        <p>
          Im Folgenden informieren wir Sie über die konkrete Datenerhebung und
          -verarbeitung auf www.hautstark.com und die Rechtsgrundlage, auf die
          wir uns jeweils stützen.
        </p>

        <div className="table--responsive-stack">
          <table>
            <tr>
              <th>Art und Zweck der Verarbeitung</th>
              <th>Rechtgrundlage</th>
            </tr>
            <tr>
              <td data-type="Art und Zweck der Verarbeitung">
                <strong>Betrieb der Webseite</strong>
                <br />
                Bei der bloß informatorischen Nutzung der Website erheben wir
                keine personenbezogenen Daten, mit Ausnahme der Daten, die Ihr
                Browser übermittelt, um Ihnen den Besuch der Website zu
                ermöglichen. Dies sind:
                <br />
                <br />
                - IP-Adresse des anfordernden Rechners
                <br />
                - Datum und Uhrzeit des Abrufes bzw. der Anforderung
                <br />
                - Name und URL der angeforderten Seite
                <br />
                - Zugriffsstatus/Http-Statuscode
                <br />
                - Jeweils übertragene Datenmenge
                <br />
                - Verweis, von welcher Seite aus diese Seite aufgerufen wurde
                <br />
                - vom Nutzer verwendeter Browser einschließlich der
                Browserversion - vom Nutzer verwendetes Betriebssystem
                <br />
                <br />
                Gemäß dem Prinzip der Datensparsamkeit und der Datenvermeidung
                erfassen wir nur die für die Nutzung des jeweiligen Bereiches
                unseres Internetangebotes vorgesehenen oder nach Ihrem Wunsch
                von Ihnen zur Kontaktaufnahme überlassenen Daten.
              </td>
              <td data-type="Rechtgrundlage">
                Die Verarbeitung der Protokolldaten dient statistischen Zwecken
                und der Verbesserung der Qualität unserer Webseite, insbesondere
                der Stabilität und der Sicherheit der Verbindung.
                Rechtsgrundlage ist Art. 6 Abs. 1 S. 1 Buchst. f DSGVO. f
                erfolgten Verarbeitung wird hiervon nicht berührt.
              </td>
            </tr>
            {/* <tr>
              <td data-type="Art und Zweck der Verarbeitung">
                <strong>Kontaktformular</strong>
                <br />
                Über unser Kontaktformular können Sie direkt mit uns in
                Verbindung treten oder aktuelle Informationen von uns anfordern.
                Dazu ist die Angabe von Name und E-Mail-Adresse verpflichtend,
                damit wir Sie bei Rückfragen kontaktieren können. Wir erheben,
                verarbeiten und nutzen die von Ihnen über ein Kontaktformular
                mitgeteilten Informationen ausschließlich für die Bearbeitung
                Ihres Anliegens.
                <br />
                Weitere Angaben sind optional und dienen uns zur Konkretisierung
                Ihrer Anfrage und zur verbesserten Abwicklung Ihres Anliegens.
                Eine Mitteilung dieser Angaben erfolgt ausdrücklich auf
                freiwilliger Basis und mit Ihrer Einwilligung. Soweit es sich
                hierbei um Angaben zu Kommunikationskanälen (beispielsweise
                E-Mail-Adresse, Telefonnummer, Faxnummer) handelt, willigen Sie
                außerdem ein, dass wir Sie auch über diesen Kommunikationskanal
                kontaktieren, um Ihr Anliegen zu beantworten.
              </td>
              <td data-type="Rechtgrundlage">
                Diese Datenverarbeitung erfolgt auf Grundlage Ihrer Einwilligung
                in die Verarbeitung der erforderlichen Daten (Art. 6 Abs. 1 S. 1
                Buchst. a) DSGVO)
                <br />
                Sie können die einmal gegebene Einwilligung jederzeit mit
                Wirkung für die Zukunft widerrufen und/oder der zukünftigen
                Verwendung Ihrer Daten widersprechen. Die Rechtmäßigkeit der
                aufgrund der Einwilligung bis zum Widerruf erfolgten
                Verarbeitung wird hiervon nicht berührt.
              </td>
            </tr> */}
            {/* <tr>
              <td data-type="Art und Zweck der Verarbeitung">
                <strong>Hautstark-Newsletter</strong>
                <br />
                Neben der rein informatorischen Nutzung unserer Webseite können
                Sie unseren Hautstark-Newsletter abonnieren, mit dem wir Sie
                über aktuelle Themen rund um die Erkrankung Neurodermitis und
                Produkte von Pierre Fabre informieren. Wenn Sie sich für unseren
                Hautstark-Newsletter anmelden, werden die folgenden Daten von
                uns erhoben, gespeichert und weiterverarbeitet:
                <br />
                <br />
                - E-Mail-Adresse
                <br />
                - Verweis, von welcher Seite aus diese Seite aufgerufen wurde
                <br />
                - Datum und die Uhrzeit des Aufrufs bzw. der Anforderung
                <br />
                - vom Nutzer verwendeter Browser einschließlich der
                Browserversion
                <br />
                - IP-Adresse des anfordernden Rechners
                <br />
                - Datum und die Uhrzeit der Anmeldung und Bestätigung
                <br />
                <br />
                Um sicherzugehen, dass Sie den Hautstark-Newsletter nur
                erhalten, wenn Sie dies auch wirklich wünschen, verwenden wir
                das sogenannte Double Opt-In Verfahren. Das bedeutet, dass wir
                Ihnen erst dann unseren Hautstark-Newsletter senden, wenn Sie
                sich ausdrücklich hierfür angemeldet und in der nachfolgenden
                Bestätigungs-E-Mail durch Anklicken des enthaltenen Links
                bestätigt haben, dass Sie den Hautstark-Newsletter erhalten
                möchten.
                <br />
                Falls Sie keinen Newsletter mehr erhalten möchten, können Sie
                jederzeit widersprechen. Den Widerruf können Sie durch Klick auf
                den in jeder Newsletter-E-Mail bereitgestellten Link oder per
                E-Mail an dpode@pierre-fabre.com erklären.
                <br />
                Wir weisen Sie darauf hin, dass wir bei Versand des Newsletters
                Tracking-Mechanismen verwenden, um Ihr Nutzerverhalten
                auszuwerten. Für diese Auswertung beinhalten die versendeten
                E-Mails sogenannte Web-Beacons bzw. Tracking-Pixel, die
                Ein-Pixel-Bilddateien darstellen, die auf unserer Webseite
                gespeichert sind. Für die Auswertungen verknüpfen wir die
                vorstehend genannten Daten und die Web-Beacons mit Ihrer
                E-Mail-Adresse und einer individuellen ID.
                <br />
                [OPTIONAL: Auch im Newsletter erhaltene Links enthalten diese
                ID.]
                <br />
                [ENTWEDER:] Die Daten werden ausschließlich pseudonymisiert
                erhoben, die IDs werden also nicht mit Ihren weiteren
                persönlichen Daten verknüpft, eine direkte Personenbeziehbarkeit
                wird ausgeschlossen.
                <br />
                [ODER:] Mit den so gewonnen Daten erstellen wir ein
                Nutzerprofil, um Ihnen den Newsletter auf Ihre individuellen
                Interessen zuzuschneiden. Dabei erfassen wir, wann Sie unsere
                Newsletter lesen, welche Links Sie in diesen anklicken und
                folgern daraus Ihre persönlichen Interessen. Diese Daten
                verknüpfen wir mit von Ihnen auf unserer Website getätigten
                Handlungen.
                <br />
              </td>
              <td data-type="Rechtgrundlage">
                Diese Datenverarbeitung erfolgt auf Grundlage Ihrer Einwilligung
                in die Verarbeitung solcher Daten, die für die Beantwortung
                Ihrer Anfrage erforderlich sind (Art. 6 Abs. 1 S. 1 Buchst. a)
                DSGVO).
              </td>
            </tr> */}
            <tr>
              <td data-type="Art und Zweck der Verarbeitung">
                <strong>Gewinnspiele</strong>
                <br />
                Von Zeit zu Zeit veranstaltet Pierre Fabre Gewinnspiele, an
                denen Sie über ein Gewinnspielformular auf der Webseite
                teilnehmen können.
                <br />
                Für die Teilnahme an den Gewinnspielen ist die Angabe von
                personenbezogenen Daten notwendig. Dabei handelt es sich um die
                E-Mail-Adresse, den Vor- und Nachnamen, das Geburtsdatum sowie
                gegebenenfalls Straße/Hausnummer, PLZ und Ort. Erfolgt die
                Bereitstellung der notwendigen Daten nicht, ist eine
                Gewinnspielteilnahme nicht möglich.
                <br />
                Die zur Teilnahme erforderlichen personenbezogenen Daten hängen
                dabei von dem jeweiligen Gewinnspiel ab. Insoweit verweisen wir
                auf die jeweiligen Teilnahmebedingungen. Andere personenbezogene
                Daten können auf freiwilliger Basis angegeben werden, um die
                Abwicklung des Gewinnspiels zu erleichtern.
                <br />
                Die Bereitstellung des Vor- und Nachnamens ist zur
                Identifizierung und zum Ausschluss unberechtigter Teilnehmer
                erforderlich. Die Angabe der E-Mail-Adresse ist zur
                Gewinnbenachrichtigung und die Angabe der postalischen Anschrift
                ist gegebenenfalls zum Versand des Gewinns erforderlich. Das
                Geburtsdatum wird zur Überprüfung der Einhaltung des
                Mindestalters verwendet.
                <br />
                Die personenbezogenen Daten werden ohne entsprechende
                Einwilligung des Gewinnspielteilnehmers nicht an Dritte
                weitergegeben. Nach Abschluss des jeweiligen Gewinnspiels werden
                alle erhobenen personenbezogenen Daten gelöscht.
                <br />
              </td>
              <td data-type="Rechtgrundlage">
                Die Datenverarbeitung im Rahmen der Gewinnspiele erfolgt im
                Hinblick auf die gemäß den Teilnahmebedingungen erforderlichen
                Pflichtangaben zur Erfüllung der vertraglichen Vereinbarungen
                (Art. 6 Abs. 1 S. 1 Buchst. b) DSGVO).
                <br />
                Soweit darüber hinaus freiwillige Angaben gemacht werden,
                erfolgt die Verarbeitung dieser personenbezogenen Daten aufgrund
                Ihrer Einwilligung (Art. 6 Abs. 1 S. 1 Buchst. a) DSGVO). Sie
                können die einmal gegebene Einwilligung jederzeit mit Wirkung
                für die Zukunft widerrufen und/oder der zukünftigen Verwendung
                Ihrer Daten widersprechen. Die Rechtmäßigkeit der aufgrund der
                Einwilligung bis zum Widerruf erfolgten Verarbeitung wird
                hiervon nicht berührt.
              </td>
            </tr>
            <tr>
              <td data-type="Art und Zweck der Verarbeitung">
                <strong>SkinQuiz</strong>
                <br />
                Pierre Fabre veranstaltet auf der Website das sogenannte
                SkinQuizz. Hierbei handelt es sich um ein Gewinnspiel, an dem
                nach Beantwortung der Quizfragen über ein Gewinnspielformular
                teilgenommen werden kann.
                <br />
                Für die Teilnahme am SkinQuizz ist die Angabe von
                personenbezogenen Daten notwendig. Insoweit verweisen wir auf
                unsere Ausführungen zu den Gewinnspielen.
                <br />
                Im Rahmen des SkinQuizz werden auch Gesundheitsdaten wie zum
                Beispiel Informationen über Ihren Hauttyp und den Krankheitsgrad
                der Neurodermitis abgefragt. Hierzu ist es erforderlich, dass
                Sie in die Verarbeitung der Gesundheitsdaten ausdrücklich
                einwilligen. Pierre Fabre wird Sie daher jeweils um Ihre
                ausdrückliche Einwilligung bitten.
              </td>
              <td data-type="Rechtgrundlage">
                Die Datenverarbeitung im Rahmen des SkinQuizzs erfolgt im
                Hinblick auf die gemäß den Teilnahmebedingungen erforderlichen
                Pflichtangaben zur Erfüllung der vertraglichen Vereinbarungen
                (Art. 6 Abs. 1 S. 1 Buchst. b) DSGVO).
                <br />
                Übermitteln Sie im Zusammenhang mit dem SkinQuizz
                Gesundheitsdaten, erfolgt die Verarbeitung auf Grundlage Ihrer
                ausdrücklichen Einwilligung gemäß Art. 9 Abs. 2 Buchst. a)
                DSGVO. Sie können die einmal gegebene Einwilligung jederzeit mit
                Wirkung für die Zukunft widerrufen und/oder der zukünftigen
                Verwendung Ihrer Daten widersprechen. Die Rechtmäßigkeit der
                aufgrund der Einwilligung bis zum Widerruf erfolgten
                Verarbeitung wird hiervon nicht berührt.
                <br />
              </td>
            </tr>
            {/* <tr>
              <td data-type="Art und Zweck der Verarbeitung">
                <strong>Meldung von Unverträglichkeiten</strong>
                <br />
                Über unser Kontaktformular können Sie Unverträglichkeiten gegen
                eines unserer Produkte melden. Dabei sind alle abgefragten
                Angaben (Name, E-Mail-Adresse) verpflichtend, um Sie bei
                Rückfragen kontaktieren zu können. Wir erheben, verarbeiten und
                nutzen die von Ihnen über ein Kontaktformular mitgeteilten
                Informationen ausschließlich für die Bearbeitung Ihres
                Anliegens. Soweit es sich hierbei um Angaben zu
                Kommunikationskanälen (beispielsweise E-Mail-Adresse,
                Telefonnummer, Faxnummer) handelt, willigen Sie außerdem ein,
                dass wir Sie auch über diesen Kommunikationskanal kontaktieren,
                um Ihr Anliegen zu beantworten.
              </td>
              <td data-type="Rechtgrundlage">
                Diese Datenverarbeitung erfolgt auf Grundlage Ihrer Einwilligung
                in die Verarbeitung der erforderlichen Daten (Art. 6 Abs. 1 S. 1
                Buchst. a) DSGVO). Sie können die einmal gegebene Einwilligung
                jederzeit mit Wirkung für die Zukunft widerrufen und/oder der
                zukünftigen Verwendung Ihrer Daten widersprechen. Die
                Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf
                erfolgten Verarbeitung wird hiervon nicht berührt.
              </td>
            </tr> */}
            <tr>
              <td data-type="Art und Zweck der Verarbeitung">
                <strong>Apothekenfinder</strong>
                <br />
                Über den Apothekenfinder können Sie Apotheken in Ihrer Umgebung
                finden, die unsere Produkte vertreiben. Dazu ist die Angabe
                entweder einer Postleitzahl, eines Ortes, einer Adresse oder
                Ihres Standortes notwendig. Wir erheben, verarbeiten und nutzen
                die von Ihnen über den Apothekenfinder mitgeteilten
                Informationen ausschließlich für die Bearbeitung Ihres
                Anliegens.
              </td>
              <td data-type="Rechtgrundlage">
                Diese Datenverarbeitung erfolgt auf Grundlage Ihrer Einwilligung
                in die Verarbeitung der erforderlichen Daten (Art. 6 Abs. 1 S. 1
                Buchst. a) DSGVO).
                <br />
                Sie können die einmal gegebene Einwilligung jederzeit mit
                Wirkung für die Zukunft widerrufen und/oder der zukünftigen
                Verwendung Ihrer Daten widersprechen. Die Rechtmäßigkeit der
                aufgrund der Einwilligung bis zum Widerruf erfolgten
                Verarbeitung wird hiervon nicht berührt.
              </td>
            </tr>
          </table>
        </div>
      </RichText>

      <RichText>
        <TypographyHeadline size="h2" tag="h2">
          Einsatz von Cookies
        </TypographyHeadline>

        <p>
          Cookies sind kleine Textdateien, die von unserem Webserver verschickt
          und auf der Festplatte Ihres Endgerätes abgelegt werden, wenn Sie
          unsere Website gerade besuchen. Wir verwenden Cookies zur
          automatischen Erfassung von Informationen. Sie sind keineswegs
          verpflichtet, die Platzierung von Cookies auf Ihrem Endgerät
          zuzulassen. Über Ihre Browser-Einstellungen können Sie die Optionen
          hinsichtlich der Verwendung oder Ablehnung von Cookies auf Ihrem
          Computer ändern. Selbstverständlich können Sie der Platzierung von
          Cookies widersprechen, die Platzierung von Cookies ablehnen oder
          bereits installierte Cookies löschen. Für das Löschen bereits
          installierter Cookies auf Ihrem Gerät gehen Sie auf die Hilfe-Seite
          des von Ihnen genutzten Browsers oder lehnen Sie die Platzierung von
          „Cookies“ ab, indem Sie dieses Feature in Ihrem Browser
          deaktivieren.Weitere Informationen über Cookies, einschließlich der
          Art und Weise, wie Sie Ihren Internet-Browser einstellen müssen, um
          diese abzulehnen oder deren Installation auf Ihrem Gerät besser zu
          kontrollieren, finden Sie hier:
          <br />-{" "}
          <a href="" target="_blank" rel="nofollow noopener noreferrer">
            Cookie-Einstellungen für Internet Explorer
          </a>
          <br />-{" "}
          <a
            href="https://support.mozilla.org/de/kb/cookies-informationen-websites-auf-ihrem-computer"
            target="_blank"
            rel="nofollow noopener noreferrer"
          >
            Cookie-Einstellungen für Firefox
          </a>
          <br />-{" "}
          <a
            href="https://support.google.com/accounts/answer/61416?hl=de&co=GENIE.Platform%3DDesktop"
            target="_blank"
            rel="nofollow noopener noreferrer"
          >
            Cookie-Einstellungen für Google Chrome
          </a>
          <br />-{" "}
          <a
            href="https://support.apple.com/de-de/guide/safari/sfri11471/mac"
            target="_blank"
            rel="nofollow noopener noreferrer"
          >
            Cookie-Einstellungen für Safari
          </a>
           und 
          <a
            href="https://support.apple.com/de-de/HT201265"
            target="_blank"
            rel="nofollow noopener noreferrer"
          >
            iOS
          </a>
          <br />
        </p>
        <p>
          <strong>Typen der von uns verwendeten Cookies</strong>
          <br />
          Wir setzen auf unserer Website verschiedene Arten von Cookies ein, die
          unterschiedlichen Zwecken dienen. Manche Cookies werden auch von
          Dritten zur Aktivierung zusätzlicher Funktionen auf unserer Website
          platziert. Die verschiedenen Arten von Cookies werden im Folgenden
          näher erläutert.
        </p>
        <ul>
          <li>
            <strong>Notwendige Cookies:</strong> Diese Cookies sind für die
            einwandfreie Funktion der Webseite unerlässlich und können nicht aus
            unseren Systemen deaktiviert werden. Sie werden in der Regel infolge
            einer Handlung, die Sie auf der Webseite zur Anforderung eines
            Dienstes ausführen, aktiviert, z. B. wenn Sie Ihre
            Datenschutzeinstellungen vornehmen, sich anmelden oder Formulare
            ausfüllen. Sie können Ihren Browser so einstellen, dass Cookies
            blockiert oder Sie über die Nutzung dieser Cookies benachrichtigt
            werden. Wenn diese Art von Cookies, die keine persönlichen Daten
            speichert, blockiert wird, können bestimmte Teile der Webseite nicht
            funktionieren. Die Nutzung der Cookies erfolgt auf der Basis von
            Art. 6 Abs. 1 S. 1 Buchst. f DSGVO.
          </li>
          <li>
            <strong>Performance- und Statistik-Cookies:</strong> Diese Cookies
            ermöglichen uns, die Anzahl der Besuche und die Traffic-Quellen
            unserer Webseite zu bestimmen, um ihre Performances zu messen und zu
            verbessern. Sie helfen uns auch, die am meisten bzw. am seltensten
            besuchten Seiten zu identifizieren und zu analysieren, wie die
            Besucher auf der Webseite navigieren. Alle durch diese Cookies
            erfassten Informationen werden aggregiert und somit anonymisiert.
            Falls Sie nicht mit der Nutzung dieser Art von Cookies einverstanden
            sind, erfahren wir nicht, wann Sie unsere Webseite besucht haben.
            Die Nutzung der Cookies erfolgt auf Basis von Art. 6 Abs. 1 S. 1
            Buchst. a DSGVO.
          </li>
          <li>
            <strong>Funktionelle Cookies:</strong> Diese Cookies verbessern die
            Performance und die Funktionsweise unserer Website; häufig nach
            einer bestimmten Aktion seitens des Benutzers. So können wir
            beispielsweise unsere Inhalte für Sie anpassen oder Ihre Vorlieben
            speichern. Die Nutzung der Cookies erfolgt auf Basis von Art. 6 Abs.
            1 S. 1 Buchst. a DSGVO.
          </li>
          <li>
            <strong>Marketing-Cookies:</strong> Diese Cookies haben
            Marketing-Funktionen. Sie können von Unternehmen genutzt werden, um
            ein Profil Ihrer Interessen zu erstellen und auf anderen Webseiten
            gezielte Werbeanzeigen einzublenden. Sie funktionieren
            ausschließlich durch die Identifizierung Ihres Browsers und Ihres
            Endgeräts. Falls Sie nicht mit der Nutzung dieser Art von Cookies
            einverstanden sind, werden Ihnen bei Ihrer Navigation auf anderen
            Webseiten weniger auf Ihre Interessen zugeschnittene Werbeanzeigen
            angezeigt. Die Nutzung der Cookies erfolgt auf Basis von Art. 6 Abs.
            1 S. 1 Buchst. a DSGVO.
          </li>
        </ul>

        <p>
          In nachstehender Liste sind die Arten von Cookies erläutert, die wir
          auf unserer Website verwenden:
        </p>

        <div className="table--responsive-scroll">
          <table>
            <tr>
              <th>Art</th>
              <th>Typ</th>
              <th>Name</th>
              <th>Anwendung</th>
              <th>Speicherdauer</th>
              <th>Zweck</th>
            </tr>

            {[
              ...cookieList.necessary,
              ...cookieList.performance,
              ...cookieList.marketing,
            ].map(([category, type, cookieName, app, duration, purpose]) => (
              <tr key={`${cookieName}-row`}>
                <td data-type="Art">{category}</td>
                <td data-type="Typ">{type}</td>
                <td data-type="Name">{cookieName}</td>
                <td data-type="Anwendung">{app}</td>
                <td data-type="Speicherdauer">{duration}</td>
                <td data-type="Zweck">
                  {Array.isArray(purpose) ? addLineBreaks(purpose) : purpose}
                </td>
              </tr>
            ))}
          </table>
        </div>

        <p>
          <strong>Verwaltung und Löschung von Cookies</strong>
          <br />
          Wir haben zur Überwachung der Verwendung von Cookies auf unserer
          Website ein Tool installiert, das bei Ihrem Besuch unserer Website
          verfügbar ist und Sie dazu einlädt, die verschiedenen Cookies, deren
          Installation Sie erlauben, zu konfigurieren. Wenn Sie die Cookies
          ablehnen, werden wir keine weiteren Cookies außer den oben
          aufgeführten funktionellen Cookies auf Ihrem Gerät installieren
          (einschließlich eines unvermeidbaren Cookies, das speichert, dass Sie
          beim Besuch der Websites keine Cookies installiert haben möchten).
          <br />
          Wir weisen jedoch darauf hin, dass Ihr Entschluss bestimmte Cookies zu
          löschen oder deren Installation abzulehnen Auswirkungen auf bestimmte
          Funktionen oder Serviceleistungen unserer Website hat.
          <br />
          <a onClick={() => cookieState.toggleCookieDialog(true)}>
            Sie können die Einstellungen für die auf dieser Website verwendeten
            Cookies über diesen Link ändern.
          </a>
        </p>

        <p>
          <strong>Weitergabe von Daten</strong>
          <br />
          <strong>
            Wir sind Teil des weltweit operierenden Pierre Fabre-Konzerns. Eine
            Weitergabe Ihrer personenbezogenen Daten erfolgt nur an die
            Muttergesellschaft Pierre Fabre S.A., Avenue Hoche 75008 Paris,
            Frankreich
            {/*  und ausschließlich zum Zwecke […]. Rechtsgrundlage für */}.{" "}
            Diese Datenweitergabe ist die Wahrung berechtigter Interessen von
            Pierre Fabre sowie deren Muttergesellschaft Pierre Fabre S.A. (Art.
            6 Abs. 1 S. 1 Buchst. f) DSGVO).
          </strong>
          <br />
          Folgende Kategorien von Empfängern, bei denen es sich im Regelfall um
          Auftragsverarbeiter handelt, erhalten gegebenenfalls Zugriff auf Ihre
          personenbezogenen Daten:
        </p>
        <ul>
          <li>
            Dienstleister, die uns dabei unterstützen, Ihnen Informationen,
            Produkte oder Serviceleistungen bereitzustellen oder Dienstleister,
            die wir mit der Leitung, dem Management unserer Aktivitäten, der
            Verwaltung oder Optimierung unserer Website oder der Verarbeitung
            der durch die Systeme gespeicherten oder übermittelten Daten
            beauftragen. Rechtsgrundlage für die Weitergabe ist dann Art. 6 Abs.
            1 S. 1 Buchst. b oder Buchst. f DSGVO, soweit es sich nicht um
            Auftragsverarbeiter handelt;
          </li>
          <li>
            Dienstleister, staatliche Stellen/Behörden, soweit dies zur
            Erfüllung einer gesetzlichen Verpflichtung erforderlich ist.
            Rechtsgrundlage für die Weitergabe ist dann Art. 6 Abs. 1 S. 1
            Buchst. DSGVO;
          </li>
          <li>
            Zur Durchführung unseres Geschäftsbetriebs eingesetzte Personen
            (z.B. Auditoren, Rechtsberater, Aufsichtsbehörden, Beteiligte bei
            Unternehmenskäufen oder der Gründung von Gemeinschaftsunternehmen).
            Rechtsgrundlage für die Weitergabe ist dann Art. 6 Abs. 1 S. 1
            Buchst. b oder Buchst. f DSGVO.
          </li>
        </ul>
        <p>
          Werden Ihre personenbezogenen Daten an eine Pierre
          Fabre-Konzerngesellschaft oder an einen Dienstanbieter übermittelt,
          die/der sich in einem Land außerhalb des europäischen Wirtschaftsraums
          (EWR) befindet, so werden wir Maßnahmen zum Schutz dieser Daten
          ergreifen und sicherstellen, dass Ihre personenbezogenen Daten
          dasselbe Schutzniveau genießen, welches ihnen auch innerhalb des EWR
          zuteilwerden würde. Einigen Drittländern bescheinigt die Europäische
          Kommission durch sog. Angemessenheitsbeschlüsse einen Datenschutz, der
          dem EWR-Standard vergleichbar ist (eine Liste dieser Länder sowie eine
          Kopie der Angemessenheitsbeschlüsse erhalten Sie hier:
          http://ec.europa.eu/justice/data-protection/international-transfers/adequacy/index_en.html).
          In anderen Drittländern, in die gegebenenfalls personenbezogene Daten
          übertragen werden, herrscht aber unter Umständen wegen fehlender
          gesetzlicher Bestimmungen kein durchgängig hohes Datenschutzniveau.
          Soweit dies der Fall ist, achten wir darauf, dass der Datenschutz
          ausreichend gewährleistet ist. Möglich ist dies über bindende
          Unternehmensvorschriften, Standard-Vertragsklauseln der Europäischen
          Kommission zum Schutz personenbezogener Daten, Zertifikate oder
          anerkannte Verhaltenskodizes. Bitte wenden Sie sich an unseren
          Datenschutzbeauftragten, wenn Sie hierzu nähere Informationen erhalten
          möchten.
          <br />
          Wir werden Ihre personenbezogenen Daten möglicherweise auch im
          Zusammenhang mit einer tatsächlichen oder eventuellen Veräußerung von
          Pierre Fabre oder im Zusammenhang mit einer tatsächlichen oder
          eventuellen Veräußerung von Vermögensgegenständen, die Pierre Fabre
          oder einem verbundenen Unternehmen gehören, gegenüber Dritten
          offenlegen. In diesem Falle sind die von uns gehaltenen, unsere Nutzer
          betreffenden personenbezogenen Daten die veräußerten
          Vermögensgegenstände.
          <br />
          Wir werden auch Anfragen nach Informationen beantworten, wenn wir vom
          Gesetzgeber dazu verpflichtet sind oder wenn eine derartige
          Offenlegung zur Wahrung unserer Interessen erforderlich ist, und/oder
          um unseren Pflichten, die sich aus einer Rechtsstreitigkeit, aus einem
          Gerichtsurteil, aus der Anfrage einer Regulierungsbehörde oder aus
          anderen gegen uns angestrengten Rechtsverfahren ergeben, nachzukommen.
        </p>

        <p>
          <strong>
            Ort und Dauer der Speicherung Ihrer personenbezogenen Daten
          </strong>
          Die Verarbeitung sowie insbesondere die Speicherung Ihrer
          personenbezogenen Daten erfolgt innerhalb der Europäischen Union,
          vorbehaltlich einer gegebenenfalls erfolgenden Weitergabe von Daten an
          Dritte.
          <br />
          In der Regel werden wir die Sie betreffenden personenbezogenen Daten
          nur so lange speichern, wie dies für die Zwecke, für die sie erhoben
          werden, erforderlich ist.
          <br />
          Pierre Fabre speichert die Sie betreffenden personenbezogenen Daten
          nur so lange speichern, wie dies für die Zwecke, für die sie erhoben
          werden, erforderlich ist.
          <br />
          Die von uns aus Sicherheitsgründen erfassten IP-Adressen werden für
          höchstens 10 Tage gespeichert.
          <br />
          Die von uns aus Sicherheitsgründen erfassten IP-Adressen werden für
          höchstens 10 Tage gespeichert.
          <br />
          Die auf Grundlage Ihres Einverständnisses mit Hilfe von Cookies und
          anderen auf unserer Website eingesetzten Trackern erfassten
          Verbindungsprotokolle werden für eine Dauer aufbewahrt, die gemäß den
          geltenden Gesetzen und Vorschriften dreizehn (13) Monate nicht
          überschreitet. Weitere Informationen hierzu finden Sie im Abschnitt
          „Cookies“.
          <br />
          Möglicherweise müssen wir Ihre personenbezogenen Daten länger
          archivieren, um den rechtlichen Verpflichtungen, denen wir
          unterliegen, Folge zu leisten, oder wenn dies hinsichtlich der
          geltenden Verjährungsfristen notwendig ist, um unsere Rechte geltend
          zu machen. Sobald Ihre personenbezogenen Daten nicht mehr für die
          Zwecke oder die Archivierung notwendig sind, um unseren rechtlichen
          Verpflichtungen nachzukommen oder den geltenden Verjährungsfristen zu
          entsprechen, stellen wir sicher, dass diese vollständig gelöscht oder
          anonymisiert werden.
        </p>

        <p>
          <strong>Maßnahmen zum Schutz Ihrer personenbezogenen Daten</strong>
          <br />
          Wir treffen technische und organisatorische Sicherheitsmaßnahmen
          entsprechend dem Stand der Technik, um sicherzustellen, dass die
          Vorgaben des europäischen und nationalen Datenschutzrechts eingehalten
          werden und um damit die durch uns verarbeiteten Daten gegen zufällige
          oder vorsätzliche Manipulationen, Verlust, Zerstörung oder gegen den
          Zugriff unberechtigter Personen zu schützen.
        </p>
        <p>
          <strong>Externe Links</strong>
          <br />
          Diese Website kann Links zu den Seiten Dritter enthalten. Da Pierre
          Fabre keinen Einfluss auf die Inhalte verlinkter Seiten Dritter hat,
          haften wir auch nicht für die Datenschutzmaßnahmen Dritter. Wir
          empfehlen Ihnen daher, die Datenschutzbestimmungen der Webseiten
          Dritter separat zu überprüfen. Die vorliegende Datenschutzerklärung
          betrifft nur personenbezogene Daten, die durch unsere Webseiten oder
          im Zusammenhang mit unserer Geschäftstätigkeit erfasst wurden.
        </p>
      </RichText>

      <RichText>
        <TypographyHeadline size="h2" tag="h2">
          Ihre Rechte
        </TypographyHeadline>

        <ul>
          <li>
            <strong>Recht auf Auskunft:</strong> Sie können von uns jederzeit
            Auskunft über Ihre von uns verarbeiteten personenbezogenen Daten
            verlangen. Sie haben das Recht, eine Kopie der Daten zu erhalten,
            die wir von Ihnen verarbeiteten.
          </li>
          <li>
            <strong>Recht auf Berichtigung, Recht auf Löschung:</strong> Sie
            haben das Recht, die Berichtigung Sie betreffender unrichtiger oder
            die Vervollständigung unvollständiger personenbezogener Daten zu
            verlangen.
          </li>
        </ul>
        <p>
          Sie haben zudem das Recht, die Löschung Sie betreffender
          personenbezogener Daten von uns zu verlangen. Dies gilt, sofern und
          soweit diese für den Verarbeitungszweck nicht mehr erforderlich sind,
          wenn Sie Ihre Einwilligung, aufgrund derer die Verarbeitung erfolgte,
          widerrufen oder einer Verarbeitung widersprochen haben und wenn es an
          einer anderen Rechtsgrundlage für die Verarbeitung fehlt oder wenn die
          Verarbeitung unrechtmäßig erfolgt ist.
          <br />
          Wir werden Ihre personenbezogenen Daten auf Ihren Wunsch hin löschen,
          wenn wir diese Daten nicht zwingend zur Vertragserfüllung benötigen
          oder zu deren Aufbewahrung gesetzlich verpflichtet sind.
        </p>

        <ul>
          <li>
            <strong>Recht auf Einschränkung der Verarbeitung:</strong> Sie haben
            das Recht, von uns die Einschränkung der Verarbeitung Ihrer
            personenbezogenen Daten zu verlangen, wenn
            <ul>
              <li>
                Sie die Richtigkeit der personenbezogenen Daten bestreiten und
                zwar für eine Dauer, die es uns ermöglicht, die Richtigkeit der
                personenbezogenen Daten zu überprüfen;
              </li>
              <li>
                die Verarbeitung unrechtmäßig ist und Sie die Löschung der
                personenbezogenen Daten ablehnen und stattdessen die
                Einschränkung der Nutzung der personenbezogenen Daten verlangen;
              </li>
              <li>
                wir die personenbezogenen Daten für die Zwecke der Verarbeitung
                nicht länger benötigen, Sie sie jedoch zur Geltendmachung,
                Ausübung oder Verteidigung von Rechtsansprüchen benötigen, oder
              </li>
              <li>
                Sie Widerspruch gegen die Verarbeitung gemäß Art. 21 Abs. 1
                DSGVO eingelegt haben, solange noch nicht feststeht, ob die
                berechtigten Gründe auf Seiten von Pierre Fabre gegenüber denen
                auf Ihrer Seite überwiegen.
              </li>
            </ul>
          </li>
          <li>
            <strong>Recht auf Datenübertragbarkeit:</strong> Sie haben das
            Recht, die Sie betreffenden personenbezogenen Daten, die Sie uns
            bereitgestellt haben, in einem strukturierten, gängigen und
            maschinenlesbaren Format zu erhalten und diese Daten einem anderen
            Verantwortlichen ohne Behinderung zu übermitteln, sofern
            <ul>
              <li>
                die Verarbeitung auf einer Einwilligung gemäß Art. 6 Abs. 1 S. 1
                Buchst. a) DSGVO oder Art. 9 Abs. 2 Buchst. a) DSGVO oder auf
                einem Vertrag gemäß Art. 6 Abs. 1 S. 1 Buchst. b) DSGVO beruht,
                und
              </li>
              <li>
                die Verarbeitung mithilfe automatisierter Verfahren erfolgt.
              </li>
            </ul>
          </li>
        </ul>

        <p>
          Soweit dies technisch machbar ist, haben Sie dabei das Recht, zu
          erwirken, dass die personenbezogenen Daten direkt von uns an einen
          anderen Verantwortlichen übermittelt werden.
        </p>

        <ul>
          <li>
            <strong>Widerspruchsrecht:</strong> Sie haben das Recht, aus
            Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit
            gegen die Verarbeitung Sie betreffender personenbezogener Daten, die
            aufgrund von Artikel 6 Abs. 1 s. 1 Buchst. e) oder f) DSGVO erfolgt,
            Widerspruch einzulegen. Wir werden Ihre vom Widerspruch erfassten
            Daten dann nicht mehr verarbeiten, es sei denn, es liegen zwingende
            schutzwürdige Gründe für die Verarbeitung vor, die Ihre Interessen,
            Rechte und Freiheiten überwiegen, oder die Verarbeitung dient der
            Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.
          </li>
          <li>
            <strong>Recht auf Widerruf einer erteilten Einwilligung:</strong>{" "}
            Sie haben das Recht, jederzeit eine von Ihnen erteilte Einwilligung
            in die Datenverarbeitung formlos und ohne Angabe von Gründen zu
            widerrufen. Durch den Widerruf wird die Rechtmäßigkeit der bis dahin
            erfolgten Verarbeitung nicht berührt.
            <br />
            Bitte richten Sie sämtliche Anfragen in Bezug auf die Geltendmachung
            Ihrer Rechte per E-Mail an{" "}
            <a href="mailto:dpode@pierre-fabre.com">dpode@pierre-fabre.com</a>.
            Wir weisen Sie darauf hin, dass wir gegebenenfalls von Ihnen einen
            Identitätsnachweis verlangen können, und wir behalten uns das Recht
            vor, Ihnen Gebühren in Rechnung zu stellen, sofern dies gesetzlich
            erlaubt ist, z. B. wenn Ihre Anfrage unbegründet oder
            unverhältnismäßig ist. Wir bemühen uns, Ihnen innerhalb der
            geltenden Fristen zu antworten.
          </li>
          <li>
            <strong>Beschwerderecht:</strong> Ungeachtet des Vorstehenden haben
            Sie das Recht, sich mit einer Beschwerde an die zuständige
            Aufsichtsbehörde zu wenden und etwaige Verstöße gegen die
            maßgeblichen Bestimmungen des Datenschutzrechts geltend zu machen.
          </li>
        </ul>

        <p>
          <strong>Änderung der Datenschutzerklärung</strong>
          <br />
          Wir behalten uns das Recht vor, die Inhalte dieser
          Datenschutzerklärung von Zeit zu Zeit im Einklang mit den gesetzlichen
          Bestimmungen zu ändern, um diese technischen und rechtlichen
          Änderungen anzupassen.
          <br />
          Wenn Sie Fragen zu dieser Datenschutzerklärung haben, kontaktieren Sie
          uns bitte über unsere Kontaktseite. Gerne können Sie auch unseren
          Datenschutzbeauftragten direkt per E-Mail:{" "}
          <a href="mailto:dpode@pierre-fabre.com">dpode@pierre-fabre.com</a>
          kontaktieren.
        </p>

        <p>Stand: August 2021</p>
      </RichText>
    </>
  );
}
